import React from 'react'
import { useIntl } from 'react-intl'
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub'
import { SidebarMenuItem } from './SidebarMenuItem'
import { useRoles } from '../../../../../hooks/userRoleContext'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const { roles } = useRoles()

  // Verifica se l'utente ha il ruolo 'admin'
  const isAdmin = roles ? roles.includes('admin') : false

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

      {/* Ordini noleggio/vendita */}
      <SidebarMenuItemWithSub
        to='#'
        title='Ordini'
        icon='/media/icons/duotune/abstract/abs034.svg'
        fontIcon='bi-archive'
      >
        <SidebarMenuItem to='/inserisci-ordine' title='Inserisci ordine' hasBullet={true} />
        <SidebarMenuItem to='/stato-ordini' title='Stato ordini' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/* Gestione visori */}
      <SidebarMenuItemWithSub
        to='#'
        title='Gestione visori'
        icon='/media/icons/duotune/general/gen017.svg'
        fontIcon='bi-archive'
      >
        <SidebarMenuItem to='/inserisci-visore' title='Inserisci visore' hasBullet={true} />
        <SidebarMenuItem to='/libro-mastro' title='Libro mastro' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/* Altri inserimenti */}
      <SidebarMenuItemWithSub
        to='#'
        title='Altri inserimenti'
        icon='/media/icons/duotune/general/gen055.svg'
        fontIcon='bi-archive'
      >
        <SidebarMenuItem to='/inserisci-piano_abbonamento' title='Inserisci piano abbonamento' hasBullet={true} />
        <SidebarMenuItem to='/inserisci-intermediario' title='Inserisci intermediario' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/* Impostazioni - solo per admin */}
      {isAdmin && (
        <>
          <SidebarMenuItemWithSub
            to='/apps/chat'
            title='Chat'
            fontIcon='bi-chat-left'
            icon='/media/icons/duotune/communication/com012.svg'
          >
            <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
            <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chat' hasBullet={true} />
            <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chat' hasBullet={true} />
          </SidebarMenuItemWithSub>

          <SidebarMenuItem
            to='/apps/user-management/users'
            icon='/media/icons/duotune/general/gen051.svg'
            title='User management'
            fontIcon='bi-layers'
          />
        </>
      )}
    </>
  )
}

export { SidebarMenuMain }
