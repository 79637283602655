import * as Yup from 'yup'
import {validationConfig1_1} from '../../../../validationConfig';

export interface ICreateOrder {
    Ragione_sociale: string
    P_IVA: string
    CF: string
    SDI: string
    PEC: string
    Email: string
    Intermediario: string
    Modello_visore: number[]
    Piano_abbonamento: string
    Lunghezza_abbonamento: number
    Simulazioni_scelte: string[]
    Opzioni_aggiuntive_scelte: string[]
    Importo_effettivo: number
    Note_aggiuntive: string
    Indirizzo_sped: string
    Appartamento_Interno_Scala_sped: string
    Citta_sped: string
    Provincia_sped: string
    CAP_sped: string
    IndirizzoSpedizioneDiversoDaFatturazione: boolean
    Indirizzo_fatt: string
    Appartamento_Interno_Scala_fatt: string
    Citta_fatt: string
    Provincia_fatt: string
    CAP_fatt: string
    Data_entrata_ordine: Date
    Data_inizio_contratto: Date
    Tipologia_cessione: string
    Metodo_pagamento: string
    Modalita_pagamento: string
}

export const createOrderSchemas = Yup.object().shape({
  //Anagrafica cliente
    Ragione_sociale: validationConfig1_1.Ragione_sociale.required
      ? Yup.string().required('Ragione sociale obbligatoria').label('RagioneSociale')
      : Yup.string().label('RagioneSociale'),
    P_IVA: validationConfig1_1.P_IVA.required
      ? Yup.string().length(11, 'La partita IVA deve avere 16 cifre al massimo').required('Partita IVA obbligatoria').label('PartitaIVA')
      : Yup.string().length(11, 'La partita IVA deve avere 16 cifre al massimo').label('PartitaIVA'),
    CF: validationConfig1_1.CF.required
      ? Yup.string().min(11, 'Il codice fiscale deve avere 11 cifre minimo').max(16, 'Il codice fiscale deve avere 16 cifre al massimo').required('Codice fiscale obbligatorio').label('CodiceFiscale')
      : Yup.string().min(11, 'Il codice fiscale deve avere 11 cifre minimo').max(16, 'Il codice fiscale deve avere 11 cifre al massimo').label('CodiceFiscale'),
    SDI: validationConfig1_1.SDI.required
      ? Yup.string().length(7, 'Il codice SDI deve avere 7 caratteri').required('Codice SDI obbligatorio').label('SDI')
      : Yup.string().length(7, 'Il codice SDI deve avere 7 caratteri').label('SDI'),
    PEC: validationConfig1_1.PEC.required
      ? Yup.string().email('PEC non valida').required('PEC obbligatoria').label('PEC')
      : Yup.string().email('PEC non valida').label('PEC'),
    Email: validationConfig1_1.Email.required
      ? Yup.string().email('Email non valida').required('Email obbligatoria').label('Email')
      : Yup.string().email('Email non valida').label('Email'),
    Intermediario: Yup.string()
      .when('IntermediarioRadio', {
        is: 'No',
        then: Yup.string().notRequired(),
        otherwise: Yup.string().required('Intermediario obbligatorio'),
      })
      .label('Intermediario'),
    //Prodotti
    Modello_visore: validationConfig1_1.Modello_visore.required
      ? Yup.array().of(Yup.number()).label('Modello_visore')
      : Yup.string().label('Modello_visore'),
    //Servizi
    Piano_abbonamento: validationConfig1_1.Piano_abbonamento
      ? Yup.string().nullable().required('Piano abbonamento obbligatorio').label('Piano_abbonamento')
      : Yup.string().label('Piano_abbonamento'),
    Lunghezza_abbonamento: validationConfig1_1.Lunghezza_abbonamento.required
      ? Yup.number().oneOf([3, 6, 9, 12, 13, 14, 15, 16], 'La lunghezza dell\'abbonamento deve essere 3, 6, 9, 12, 13, 14, 15 o 16 mesi').required('Lunghezza abbonamento obbligatoria').label('Lunghezza_abbonamento')
      : Yup.number().oneOf([3, 6, 9, 12, 13, 14, 15, 16], 'La lunghezza dell\'abbonamento deve essere 3, 6, 9, 12, 13, 14, 15 o 16 mesi').label('Lunghezza_abbonamento'),
    Simulazioni_scelte: Yup.array()
    .of(Yup.string())
    .when('Piano_abbonamento', {
      is: 'Immersive', // condizione
      then: Yup.array()
        .of(Yup.string())
        .min(1, 'Simulazioni scelte obbligatorie')
        .max(6, 'Puoi selezionare solo 6 simulazioni con il piano Immersive') // validazione per "Immersive"
        .nullable()
        .label('Simulazioni_scelte'),
      otherwise: Yup.array().of(Yup.string()).when('Piano_abbonamento', {
        is: 'Kickstart', // condizione
        then: Yup.array()
          .of(Yup.string())
          .min(1, 'Simulazioni scelte obbligatorie')
          .max(2, 'Puoi selezionare solo 2 simulazioni con il piano Kickstart') // validazione per "Kickstart"
          .nullable()
          .label('Simulazioni_scelte'),
        otherwise: Yup.array().of(Yup.string()).when('Piano_abbonamento', {
          is: 'DiveVR', // condizione
          then: Yup.array()
            .of(Yup.string())
            .min(1, 'Simulazioni scelte obbligatorie')
            .max(3, 'Puoi selezionare solo 3 simulazioni con il piano DiveVR') // validazione per "DiveVR"
            .nullable()
            .label('Simulazioni_scelte'),
          otherwise: Yup.array()
            .of(Yup.string())
            .min(1, 'Simulazioni scelte obbligatorie') // validazione per altri piani (Matrix)
            .nullable()
            .label('Simulazioni_scelte')
        }),
      }),
    }),
    Opzioni_aggiuntive_scelte: validationConfig1_1.Opzioni_aggiuntive_scelte.required
      ? Yup.array().of(Yup.string()).label('Opzioni_aggiuntive_scelte')
      : Yup.string().label('Opzioni_aggiuntive_scelte'),
    Importo_effettivo: validationConfig1_1.Importo_effettivo.required
      ? Yup.number().min(1, 'L\'importo minimo è 1€').required('Importo effettivo obbligatorio').label('Importo_effettivo')
      : Yup.number().min(1, 'L\'importo minimo è 1€').label('Importo_effettivo'),
    //Indirizzo di spedizione
    Indirizzo_sped: validationConfig1_1.Indirizzo_sped.required
      ? Yup.string().required('Indirizzo di spedizione obbligatorio').label('Indirizzo_sped')
      : Yup.string().label('Indirizzo_sped'),
    Appartamento_Interno_Scala_sped: validationConfig1_1.Appartamento_Interno_Scala_sped.required
      ? Yup.string().required('Appartamento, Interno, scala di spedizione obbligatori').label('Appartamento_Interno_Scala_sped')
      : Yup.string().label('Appartamento_Interno_Scala_sped'),
    Citta_sped: validationConfig1_1.Citta_sped.required
      ? Yup.string().required('Città di spedizione obbligatoria').label('Citta_sped')
      : Yup.string().label('Citta_sped'),
    Provincia_sped: validationConfig1_1.Provincia_sped.required
      ? Yup.string().required('Provincia di spedizione obbligatoria').label('Provincia_sped')
      : Yup.string().label('Provincia_sped'),
    CAP_sped: validationConfig1_1.CAP_sped.required
      ? Yup.string().required('CAP di spedizione obbligatorio').label('CAP_sped')
      : Yup.string().label('CAP_sped'),
    //Checkbox se tenere stesso indirizzo
    IndirizzoSpedizioneDiversoDaFatturazione: Yup.boolean(),
    //Indirizzo di fatturazione
    Indirizzo_fatt: Yup.string()
      .when('IndirizzoSpedizioneDiversoDaFatturazione', {
        is: true,
        then: Yup.string().required('Indirizzo di fatturazione richiesto').label('Indirizzo_fatt'),
        otherwise: Yup.string().notRequired()
    }),
    Appartamento_Interno_Scala_fatt: Yup.string()
      .when('IndirizzoSpedizioneDiversoDaFatturazione', {
        is: true,
        then: Yup.string().label('Appartamento_Interno_Scala_fatt'),
        otherwise: Yup.string().notRequired()
    }),
    Citta_fatt: Yup.string()
      .when('IndirizzoSpedizioneDiversoDaFatturazione', {
        is: true,
        then: Yup.string().required('Città di fatturazione obbligatoria').label('Citta_fatt'),
        otherwise: Yup.string().notRequired()
    }),
    Provincia_fatt: Yup.string()
      .when('IndirizzoSpedizioneDiversoDaFatturazione', {
        is: true,
        then: Yup.string().required('Provincia di fatturazione obbligatoria').label('Provincia_fatt'),
        otherwise: Yup.string().notRequired()
    }),
    CAP_fatt: Yup.string()
      .when('IndirizzoSpedizioneDiversoDaFatturazione', {
        is: true,
        then: Yup.string().required('CAP di fatturazione obbligatoria').label('CAP_fatt'),
        otherwise: Yup.string().notRequired()
    }),
    //Fatturazione
    Data_entrata_ordine: validationConfig1_1.Data_entrata_ordine.required
      ? Yup.date().required('Data entrata ordine obbligatoria').label('Data_entrata_ordine')
      : Yup.string().nullable().label('Data_entrata_ordine'),
    Data_inizio_contratto: validationConfig1_1.Data_inizio_contratto
      ? Yup.date().label('Data_inizio_contratto')
      : Yup.string().nullable().label('Data_inizio_contratto'),
    Tipologia_cessione: validationConfig1_1.Tipologia_cessione.required
      ? Yup.string().oneOf(['Noleggio','Vendita','Comprati loro']).required('Tipologia cessione obbligatoria').label('Tipologia_cessione')
      : Yup.string().label('Tipologia_cessione'),
    Metodo_pagamento: validationConfig1_1.Metodo_pagamento.required
      ? Yup.string().oneOf(['Carta','Paypal','Bonifico','Ri.Ba','SDD','RID']).required('Metodo pagamento obbligatorio').label('Metodo_pagamento')
      : Yup.string().label('Metodo_pagamento'),
    Modalita_pagamento: validationConfig1_1.Modalita_pagamento.required
      ? Yup.string().oneOf(['Unica soluzione','Mensile']).required('Modalità pagamento obbligatorio').label('Modalita_pagamento')
      : Yup.string().label('Modalita_pagamento'),
});

export const inits: ICreateOrder = {
    Ragione_sociale: 'personal',
    P_IVA: '43454445678',
    CF: '3445666668908765',
    SDI: '3466587',
    PEC: 'cabboneso@pec.it',
    Email: 'cabboneso@gmail.it',
    Intermediario: '',
    Modello_visore: [1],
    Piano_abbonamento: 'Matrix',
    Lunghezza_abbonamento: 3,
    Simulazioni_scelte: ['Cartellonistica', 'Videoterminali'],
    Opzioni_aggiuntive_scelte: ['Remote device app', 'Formazione formatori'],
    Importo_effettivo: 1800,
    Note_aggiuntive: 'dsfdsfsdf',
    Indirizzo_sped: 'Via Leone XIII',
    Appartamento_Interno_Scala_sped: '1',
    Citta_sped: 'Casatenovo',
    Provincia_sped: 'Lecco',
    CAP_sped: '23880',
    IndirizzoSpedizioneDiversoDaFatturazione: false,
    Indirizzo_fatt: 'Via Leone XIII',
    Appartamento_Interno_Scala_fatt: '1',
    Citta_fatt: 'Casatenovo',
    Provincia_fatt: 'Lecco',
    CAP_fatt: '23880',
    Data_entrata_ordine: new Date('2023-07-07'),
    Data_inizio_contratto: new Date('2023-07-07'),
    Tipologia_cessione: 'Noleggio',
    Metodo_pagamento: 'Carta',
    Modalita_pagamento: 'Unica soluzione',
}
